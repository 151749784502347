import React from 'react';
import { graphql } from 'gatsby';
import GatsbyLink from '../components/GatsbyLink'
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import RelatedPosts from '../components/Posts/Related/RelatedPosts';
import { RenderShortcode } from '../components/RenderShortcode';
import { Image } from '../components/Image';
import './post.scss';
import { decodeEntities } from '../utils/helpers';

export const BlogPostTemplate = ({
  content,
  categories,
  title,
  date,
  blogSlug,
  showAuthor
}) => {
  return (
    <section className="single-post">
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            <div className="wrap">
              <RenderShortcode content={content} />
            </div>
            {showAuthor &&
            <div className="meta">
              <h4>Posted by</h4>
              <div className="author">
                {author && author.avatar_urls && <div className="avatar"><Image src={author.avatar_urls.wordpress_48}/></div>}
                <div>
                  {author && <GatsbyLink to={`/author/${author.slug}/`} className="author">{author.name}</GatsbyLink>}
                  {date && <span className="date">{date}</span>}
                </div>
              </div>
            </div>
            }
            {categories && categories.length ? (
              <div className="taxonomy">
                <h4>Categories</h4>
                <ul className="taglist">
                  {categories.map(category => (
                    <li key={`${category.slug}cat`}>
                      {category.slug &&
                        <GatsbyLink to={category.path}>
                          {category.name}
                        </GatsbyLink>
                      }
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

const BlogPost = ( props ) => {
  const { data, location } = props;
  const { wordpressPost: post, site, siteSettings, wordpressWpSettings } = data;
  if (!post) return null;
  const { title, content, featured_media, yoast, categories, date, author } = post;
  const { blogSlug } = wordpressWpSettings;
  const realtedCategory = categories[0].wordpress_id
  return (
    <Layout>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={yoast.metaDescription}
      />
      <PageHeader headerTitle={title} headerSubTitle={date} headerBackgroundImage={featured_media} location={location} />
      <BlogPostTemplate
        content={content}
        categories={categories}
        title={title}
        date={date}
        author={author}
        blogSlug={blogSlug}
        showAuthor={siteSettings.options.showAuthor}
      />
      <RelatedPosts category={realtedCategory} action={{url: '/blog/', title: 'View all'}} />
    </Layout>
  )
}

export default Previewable(BlogPost, 'post');

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
      }
    },
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ... GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      categories {
        name
        slug
        path
        wordpress_id
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
  }
`
